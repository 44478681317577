var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"wrapper page-personal"},[_c('Breadcrumbs',{staticClass:"--user-profile",attrs:{"paths":[
        { href: '/', title: 'Главная' },
        {
          title: 'Профиль',
        },
        {
          title: _vm.profile ? _vm.profile.name : '...',
        },
      ]}}),_c('ProfileDashboard',{attrs:{"user":_vm.profile}}),_c('ProfilePromotionsBanner')],1),(!_vm.isLoading && _vm.profile)?_c('div',{staticClass:"wrapper wrapper--open"},[_c('DropInventory',{ref:"dropInventory",attrs:{"userId":_vm.profile.uuid,"sortingOptions":[
        { code: 'priceDesc', name: 'Дорогие' },
        { code: 'dateDesc', name: 'Последние' },
      ],"mobileRowSize":2},scopedSlots:_vm._u([{key:"itemTopRight",fn:function(drop){return [_c('ItemSource',{attrs:{"drop":drop}})]}},{key:"headerLeft",fn:function({
          selectedDropSource,
          showFilters,
          setSelectedDropSource,
        }){return [_c('DropSourceSwitcher',{attrs:{"selectedDropSource":selectedDropSource,"showFilters":showFilters,"setSelectedDropSource":setSelectedDropSource}})]}}],null,false,3980671803)})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }