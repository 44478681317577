
import { Component, Vue } from 'vue-property-decorator';
import Button from '@/components/Buttons/Button.vue';

@Component({components: {Button}})
export default class ProfilePromotionsBanner extends Vue {

  scrolled: boolean = false;

  mounted() {
    window.addEventListener('scroll', this.onScroll);
  }

  onScroll(e) {
    const windowTop = e.target.documentElement.scrollTop;
    if (windowTop > 0) this.scrolled = true;
  }

}
