
import { Component, Vue } from 'vue-property-decorator';

import Breadcrumbs from '@/components/Layout/Breadcrumbs.vue';

import Head from '@/components/Profile/ProfileDashboard/ProfileDashboard.vue';
import ProfileDashboard from '@/components/Profile/ProfileDashboard/ProfileDashboard.vue';
import ProfilePromotionsBanner from '@/components/Profile/ProfilePromotionsBanner.vue';
import { UserProfile } from '@/types/User';
import { getUserProfile } from '@/requests/User/getUser/getUser';
import LoadingScreen from '@/components/Layout/LoadingScreen.vue';
import DropInventory from '@/components/DropInventory/DropInventory.vue';
import ItemSource from '@/components/Item/ItemSource.vue';
import DropSourceSwitcher from '@/components/DropInventory/InventoryHeader/DropSourceSwitcher.vue';

@Component({
  components: {
    DropSourceSwitcher,
    ItemSource,
    DropInventory,
    LoadingScreen,
    ProfileDashboard,
    Breadcrumbs,
    Head,
    ProfilePromotionsBanner,
  },
})
export default class PublicProfile extends Vue {
  isLoading: boolean = true;
  profile: UserProfile = null;

  created() {
    if (!this.$route.params.id) return this.$router.push('/');

    this.$setPageTitle('Профиль ... | DOTALOOT');
  }

  get routeUserId() {
    return this.$route.params.id;
  }

  async mounted() {
    try {
      this.profile = await getUserProfile(this.routeUserId);

      if (!this.profile) return this.$router.push('/');
      this.$setPageTitle(`Профиль «${this.profile.name}» | DOTALOOT`);
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  }
}
